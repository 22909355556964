import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import LinkWithIcon from "components/external-link-with-icon/"
import Carousel from "components/case-study-carousel/"
import Quote from "components/case-study-quote/"

const StyledSection = styled(Section)`
  padding-top: 56px !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const StyledQuote = styled(Quote)`
  margin-top: 56px;

  ${breakpoint.medium`
    margin-top: 120px;
  `}
`

const StyledCarousel = styled(Carousel)`
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}
`

const Results =  () => {
  const data = useStaticQuery(graphql`
    query {
      nicholasPhoto: file(
        relativePath: { eq: "case-studies/acgen/nicholas-tatonetti.png" }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      carouselImageOne: file(
        relativePath: { eq: "case-studies/acgen/results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/acgen/results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/acgen/results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/acgen/results-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFive: file(
        relativePath: { eq: "case-studies/acgen/results-image-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              A usable, scalable, mobile-friendly platform to generate and
              validate genetics hypotheses
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Initially, the ACGEN platform design was only going to be a
              desktop product. After seeing the desktop and mobile versions, the
              client saw the enormous potential for providing researchers with
              access to the platform from any device and decided to develop a
              mobile version.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              The design system meant that every single component of the product
              was documented so that Columbia’s in-house developers could scale
              the platform as much as they needed, without having to come back
              to us to redesign new pages or elements.{" "}
            </Trans>
          </p>
          <br />
          <LinkWithIcon
            iconColor={colors.indiblue}
            text="Launch the design system"
            href="https://acgen-design-system.indicius.now.sh/introduction"
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </div>

      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img
            fluid={data.carouselImageOne.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageTwo.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
      </StyledCarousel>

      <div className="container">
        <StyledQuote
          quote={t(
            "“We are super excited about the designs and happy with the direction that's being taken. I'm very satisfied!”"
          )}
          author="Nicholas Tatonetti"
          position={t(
            "PhD, Director, Clinical Informatics, Institute for Genomic Medicine"
          )}
          image={data.nicholasPhoto.childImageSharp.fixed}
          backgroundColor={colors.indiblue}
          color={colors.white}
        />
      </div>
    </StyledSection>
  )
}

export default Results;