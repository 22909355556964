import React from "react"

// Components
import Section from "components/case-study-section/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

const Challange =  () => (
  <Section>
    <div className="container">
      <div className="section__title">
        <div className="sticky">
          <p>
            <Trans>The challenge</Trans>
          </p>
        </div>
      </div>

      <div className="section__content">
        <h3>
          <Trans>
            Optimizing the ACGEN platform so scientists can do genetic analysis
            from anywhere
          </Trans>
        </h3>
        <br />
        <p>
          <Trans>
            Columbia University’s Biomedical Informatics Department had created
            a functional prototype for ACGEN, a platform for genomic analysis.
            But it was difficult to use and slow to load.{" "}
          </Trans>
          <b>
            <Trans>
              In order to be useful, it needed to be agile, user friendly, and
              fast.
            </Trans>
          </b>
        </p>
        <br />
        <p>
          <Trans>
            The Biomedical Informatics team needed help optimizing the platform
            design so that researchers could search for chromosomes. Plus, they
            were thinking ahead to the future, and wanted to make an easily
            scalable platform that they could eventually manage with their own
            in-house dev team. That’s where we come in.{" "}
          </Trans>
        </p>
      </div>
    </div>
  </Section>
)

export default Challange;