import React from "react"
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"

// Icons
import Arrow from "assets/icons/icon-arrow-right.inline.svg"

const StyledLink = styled.a`
  display: inline-flex;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;

  &:hover {

    svg {
      
      circle {
        fill: ${props => props.iconColor === colors.indiblue ? colors.midnight : colors.indiblue};
      }
    }
  }

  svg {
    flex-shrink: 0;
    width: 24px;§
    height: 25px;
    display: inline-block;
    margin-right: 8px;
    
    circle {
      fill: ${props => props.iconColor === colors.indiblue ? colors.indiblue : colors.supernova};
      transition: all .3s;
    }
  }
`

const LinkWithIcon = props => (
  <StyledLink
    href={props.href}
    iconColor={props.iconColor}
    {...props}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Arrow />
    {props.text}
  </StyledLink>
)

export default LinkWithIcon
