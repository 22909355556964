import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints"
import { colors } from "utils/variables/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-bottom: 0 !important;
`

const ParallaxImage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/acgen/ux-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="div"
      className="parallax"
      fluid={data.file.childImageSharp.fluid}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    />
  )
}

const StyledParallaxImage = styled.div`
  height: 200px;

  ${breakpoint.small`
    height: 400px;
  `}

  ${breakpoint.medium`
    height: 636px;
  `}

  .parallax {
    width: 100%;
    height: 100%;
    margin-top: 40px;

    &::before {
      @supports (-webkit-touch-callout: none) {
        background-attachment: unset !important;
      }
    }

    ${breakpoint.medium`
      margin-top: 80px;
    `}
  }
`

const Ux =  () => (
  <StyledSection backgroundColor={colors.cream}>
    <div className="container">
      <div className="section__title"></div>

      <div className="section__content">
        <p className="title">
          <Trans>3. UX Analysis & Recommendations</Trans>
        </p>
        <p>
          <Trans>
            Then we analyzed the existing prototype that Columbia had, and
            mapped out UX recommendations for the best user experience.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            This intensive discovery phase and close collaboration with the
            Columbia team made for a smooth, straightforward process to develop
            the product’s new UI and design system.
          </Trans>
        </p>
      </div>
    </div>

    <StyledParallaxImage>
      <ParallaxImage />
    </StyledParallaxImage>
  </StyledSection>
)

export default Ux;