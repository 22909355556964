import React from "react"
import styled, { css } from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Icons
import Arrow from "assets/icons/icon-arrow-left.inline.svg"

const StyledCarousel = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  overflow: hidden;
  cursor: none;

  ${breakpoint.small`
    padding: 0 40px;
  `}

  ${breakpoint.medium`
    flex-wrap: nowrap;
    padding: 0;
  `}

  .slide {
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 32px;
    transition: all 0.3s ease;

    &:last-child {
      margin-bottom: 0;
    }

    ${breakpoint.medium`
      max-width: 928px;
      margin: 0 40px;

      &.align--with-title {
        margin-left: calc((100vw - 928px) / 2);
      }

      &.align--with-content {
        margin-left: calc(((100vw - 928px) / 2) + 266px);
      }
    `}

    img {
      display: block;
    }
  }
`

const StyledArrow = styled.div`
  width: 88px;
  height: 88px;
  position: ${props => (props.active ? "fixed" : "absolute")};
  display: none;
  align-items: center;
  justify-content: center;
  background-color: ${colors.midnight};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  opacity: 1;
  transform: ${props =>
    props.cursorIsOnTheLeftSide ||
    (props.isOnLastSlide && !props.cursorIsOnTheLeftSide)
      ? "rotate(0)"
      : "rotate(180deg)"};
  transition: transform 0.3s ease, opacity 0.6s ease;
  mix-blend-mode: hard-light;
  z-index: 9999;
  cursor: none;
  pointer-events: none;

  ${breakpoint.large`
    display: flex;
  `}

  ${props =>
    props.disabled
      ? css`
          opacity: 0.3;
        `
      : null}

  ${props =>
    !props.active && props.cursorIsOnTheLeftSide
      ? css`
          top: 40% !important;
          left: 32px !important;
          transition: all 0.6s ease;
        `
      : null}

  ${props =>
    !props.active && !props.cursorIsOnTheLeftSide
      ? css`
          top: 40% !important;
          left: calc(100vw - 120px) !important;
          transition: all 0.6s ease;
        `
      : null}

  svg {
    width: 24px;

    * {
      fill: ${colors.white};
    }
  }

  span {
    max-width: 52px;
  }
`

const CarouselCounter = styled.div`
  display: none;

  ${breakpoint.medium`
    max-width: 928px;
    display: block;
    margin: 16px auto 0 auto;
    font-size: 16px;
    text-align: right;
  `}
`

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id ? this.props.id : "",
      carouselIsEnabled: false,
      cursorIsOnTheLeftSide: false,
      mouseIsOver: false,
      currentSlide: 1,
      slideLength: 0,
    }
  }

  componentDidMount = () => {
    this.checkViewportSize()
    this.handleSlideTransition()
    window.addEventListener("resize", this.checkViewportSize)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkViewportSize)
  }

  checkViewportSize = () => {
    const viewportWidth = window.innerWidth

    if (viewportWidth >= 1024) {
      if (!this.state.carouselIsEnabled) {
        this.setState({
          carouselIsEnabled: true,
        })
      }
    } else {
      if (this.state.carouselIsEnabled) {
        this.setState({
          carouselIsEnabled: false,
        })
      }
    }

    // Slide length
    const slideLength = document.querySelectorAll(
      `#${this.state.id} > [data-slide]`
    ).length
    this.setState({
      slideLength: slideLength,
    })
  }

  handleMouseMove = event => {
    if (this.state.carouselIsEnabled) {
      const windowWidth = window.innerWidth
      const xCoord = event.clientX
      const yCoord = event.clientY

      // Follows cursor
      const cursor = document.querySelector(
        `#${this.state.id} > #carouselCursor`
      )
      cursor.style.top = yCoord - 36 + "px"
      cursor.style.left = xCoord - 36 + "px"

      // If the cursor is in the right half screen,
      // will rotate the cursor
      if (xCoord >= windowWidth / 2) {
        if (this.state.cursorIsOnTheLeftSide) {
          this.setState({
            cursorIsOnTheLeftSide: false,
          })
        }
      } else {
        if (!this.state.cursorIsOnTheLeftSide) {
          this.setState({
            cursorIsOnTheLeftSide: true,
          })
        }
      }

      // Sets coordinates to show cursor
      if (!this.state.mouseIsOver) {
        this.setState({
          mouseIsOver: true,
        })
      }
    }
  }

  handleMouseLeave = () => {
    if (this.state.carouselIsEnabled) {
      if (this.state.mouseIsOver) {
        this.setState({
          mouseIsOver: false,
        })
      }
      if (this.state.currentSlide === 1 && this.state.cursorIsOnTheLeftSide) {
        this.setState({
          cursorIsOnTheLeftSide: false,
        })
      }
    }
  }

  handleClick = () => {
    if (this.state.carouselIsEnabled) {
      if (this.state.cursorIsOnTheLeftSide) {
        if (this.state.currentSlide !== 1) {
          this.setState(
            {
              currentSlide: this.state.currentSlide - 1,
            },
            () => this.handleSlideTransition()
          )
        }
      } else {
        if (this.state.currentSlide !== this.state.slideLength) {
          this.setState(
            {
              currentSlide: this.state.currentSlide + 1,
            },
            () => this.handleSlideTransition()
          )
        } else {
          this.setState(
            {
              currentSlide: 1,
            },
            () => this.handleSlideTransition()
          )
        }
      }
    }
  }

  handleSlideTransition = () => {
    const slides = document.querySelectorAll(`#${this.state.id} > [data-slide]`)
    let slideTranslation = 0

    // Let's build the transition amount
    for (let x = 0; x < this.state.currentSlide - 1; x++) {
      slideTranslation = slideTranslation + (slides[x].clientWidth + 80)
    }

    slides.forEach(slide => {
      slide.style.transform = "translateX(-" + slideTranslation + "px)"
    })
  }

  render = props => (
    <React.Fragment>
      <StyledCarousel
        id={this.props.id}
        className={this.props.className}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      >
        <StyledArrow
          id="carouselCursor"
          active={this.state.mouseIsOver}
          cursorIsOnTheLeftSide={this.state.cursorIsOnTheLeftSide}
          disabled={
            this.state.currentSlide === 1 && this.state.cursorIsOnTheLeftSide
          }
          isOnLastSlide={this.state.currentSlide === this.state.slideLength}
        >
          {this.state.currentSlide === this.state.slideLength &&
          !this.state.cursorIsOnTheLeftSide ? (
            <span>Start Again</span>
          ) : (
            <Arrow />
          )}
        </StyledArrow>
        {this.props.children}
      </StyledCarousel>
      <CarouselCounter>
        <p>
          0{this.state.currentSlide}/0{this.state.slideLength}
        </p>
      </CarouselCounter>
    </React.Fragment>
  )
}

export default Carousel
