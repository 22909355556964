import React from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

// Icons
import StickyOne from "assets/icons/case-studies/acgen/sticky-1.inline.svg"
import StickyTwo from "assets/icons/case-studies/acgen/sticky-2.inline.svg"
import StickyThree from "assets/icons/case-studies/acgen/sticky-3.inline.svg"
import StickyFour from "assets/icons/case-studies/acgen/sticky-4.inline.svg"
import StickyFive from "assets/icons/case-studies/acgen/sticky-5.inline.svg"
import StickySix from "assets/icons/case-studies/acgen/sticky-6.inline.svg"
import StickySeven from "assets/icons/case-studies/acgen/sticky-7.inline.svg"
import StickyEight from "assets/icons/case-studies/acgen/sticky-8.inline.svg"

const Wrapper = styled.div`
  max-width: 928px;
  display: flex;
  flex-wrap: wrap;
  margin: 32px auto 0 auto;
  padding-left: 24px;

  ${breakpoint.small`
    padding-left: 48px;
  `}

  ${breakpoint.medium`
    padding-left: 0;
    margin-top: 68px;
  `}
`

const Stickies = styled.div`
  width: auto;
  display: inline-block;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 32px;

  &.w--100 {
    width: 100%;
    display: flex;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${breakpoint.medium`
    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  `}

  .title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  .stickies {
    display: flex;
    overflow-x: auto;

    svg {
      flex-shrink: 0;
      margin-right: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Discovery =  () => (
  <Section backgroundColor={colors.cream}>
    <div className="container">
      <div className="section__title"></div>
      <div className="section__content">
        <p className="title">
          <Trans>1. Discovery</Trans>
        </p>
        <p>
          <Trans>
            We kicked off the project with a discovery workshop to interview the
            department’s experts and identify the key challenges, opportunities,
            and goals for the platform.
          </Trans>
        </p>
      </div>
    </div>

    <Wrapper>
      <Stickies className="w--100">
        <p className="title">
          <Trans>Expert interviews</Trans>
        </p>
        <div className="stickies">
          <StickyOne />
          <StickyTwo />
          <StickyThree />
          <StickyFour />
        </div>
      </Stickies>

      <Stickies>
        <p className="title">
          <Trans>Project goal</Trans>
        </p>
        <div className="stickies">
          <StickyFive />
        </div>
      </Stickies>

      <Stickies>
        <p className="title">
          <Trans>Challenges ahead</Trans>
        </p>
        <div className="stickies">
          <StickySix />
          <StickySeven />
          <StickyEight />
        </div>
      </Stickies>
    </Wrapper>
  </Section>
)

export default Discovery;