import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "./featured-image"

const Hero = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CaseStudyHero
        title={t(
          "Redesigning Columbia University's genomics app for on-the-go analysis"
        )}
        details={[t("Columbia University"), "New York, USA", "2019"]}
        tags={[
          t("Branding"),
          t("UX/UI Design"),
          t("Design System"),
          t("Front-end Development"),
        ]}
        backgroundColor={colors.supernova}
        color={colors.midnight}
        bulletColor={colors.indiblue}
      />
      <FeaturedImage />
    </React.Fragment>
  )
}

export default Hero;
