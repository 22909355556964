import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Quote from "components/case-study-quote/"

// Images

const StyledQuote = styled(Quote)`
  margin-top: 40px;
`

const Solution = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/acgen/nicholas-tatonetti.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Revamped branding, better UX, and a design system for scalability
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Designing ACGEN involved understanding complicated,
              industry-specific topics. We immersed ourselves in the subject
              matter and worked closely alongside the client during the initial
              phases to make sure we were aligned on the shared vision of the
              product.
            </Trans>
          </p>

          <StyledQuote
            quote={t(
              "“The faculty are going to lose their minds when they realize they can RUN A GENOMICS ANALYSIS WHILE THEY’RE ON ROUNDS!!!”"
            )}
            author="Nicholas Tatonetti"
            position={t(
              "PhD, Director, Clinical Informatics, Institute for Genomic Medicine"
            )}
            image={data.file.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />
        </div>
      </div>
    </Section>
  )
}

export default Solution;