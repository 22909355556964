import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  overflow-x: hidden;
`

const ImageWrapper = styled.div`
  max-width: 1280px;
  margin: 32px auto 0 auto;

  ${breakpoint.medium`
    margin-top: 80px;
  `}

  .image {
    max-width: calc(100% - 16px);
    display: block;
    margin-bottom: 32px;

    ${breakpoint.small`
      max-width: 75%;
    `}

    ${breakpoint.medium`
      margin-bottom: 80px;
    `}

    &:nth-child(even) {
      margin-left: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Branding = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/acgen/branding-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageTwo: file(
        relativePath: { eq: "case-studies/acgen/branding-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title"></div>

        <div className="section__content">
          <p className="title">
            <Trans>2. Branding</Trans>
          </p>
          <p>
            <Trans>
              We hosted a one-day branding workshop to help the team align on
              the why, how, and what behind this project. This makes it easy to
              align the visual branding to be aligned with that brand vision.{" "}
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <div
          className="image"
          data-aos="indicius-slide-right"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="ACGEN Branding"
          />
        </div>
        <div
          className="image"
          data-aos="indicius-slide-left"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="ACGEN Branding"
          />
        </div>
      </ImageWrapper>
    </StyledSection>
  )
}

export default Branding
