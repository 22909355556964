import React from "react"

// Libraries
import styled from "styled-components"
import Img from "gatsby-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Icons
import Caret from "assets/icons/icon-quotes-caret.svg"

const StyledQuote = styled.div`
  width: 100%;

  .quote {
    width: 100%;
    position: relative;
    padding: 24px;
    margin-bottom: 32px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    font-weight: 500;
    border-radius: 8px;

    &::before {
      content: url("${Caret}");
      position: absolute;
      bottom: -31px;
      left: 24px;
    }

    ${breakpoint.small`
      text-align: left;
    `}
  }

  .quote__author {
    display: flex;

    ${breakpoint.small`
      flex-direction: row;
      padding-left: 12px;
    `}

    .author__image {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: flex-start;
      margin-right: 16px;
      border-radius: 50%;
      overflow: hidden;
    }

    .author__info {
      font-size: 18px;
      line-height: 1.4em;

      .name {
        font-weight: 700;
      }
    }
  }
`

const CaseStudyQuote = props => (
  <StyledQuote
    backgroundColor={props.backgroundColor}
    color={props.color}
    className={props.className}
  >
    <div className="quote">
      <p>{props.quote}</p>
    </div>
    <div className="quote__author">
      <div className="author__image">
        <Img fixed={props.image} alt={props.author} />
      </div>
      <div className="author__info">
        <p className="name">{props.author}</p>
        <p>{props.position}</p>
      </div>
    </div>
  </StyledQuote>
)

export default CaseStudyQuote
