import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

const StyledSection = styled.section`
  padding: 40px 0 !important;
  background-color: ${props => props.backgroundColor};

  ${breakpoint.medium`
    padding-top: 80px !important;
  `}

  .container {
    max-width: 928px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 24px;
    margin: 0 auto;

    ${breakpoint.small`
      padding: 0 48px;
    `}

    ${breakpoint.large`
      padding: 0;
    `}
  }

  .section__title {
    width: 100%;
    margin-bottom: 8px;
    color: ${colors.indiblue};
    font-weight: 500;

    ${breakpoint.medium`
      width: 224px;
    `}

    svg.sticky {
      position: sticky;
      top: 32px;
    }
  }

  .section__content {
    width: 100%;
    font-size: 18px;

    ${breakpoint.medium`
      max-width: 640px;
      width: calc(100% - 266px);
      margin: 0 auto;
    `}

    p {
      font-size: 18px;
      line-height: 1.4em;
      // margin-bottom: 40px;

      ${breakpoint.medium`
        // margin-bottom: 80px;
      `}
    }

    h3 {
      margin-bottom: 8px;
      font-weight: 500;

      ${breakpoint.medium`
        margin-bottom: 0;      
      `}
    }

    h3 + br + .subtitle {
      margin-top: 16px;
    }

    .subtitle {
      color: ${colors.indiblue};
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .title {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 500;

      &.title--small {
        font-size: 18px;
      }
    }

    .strikethrough {
      color: ${colors.indiblue};
      transition: all 0.3s;

      &::before {
        background-color: ${colors.supernova};
      }

      &:hover {
        color: ${colors.midnight};
      }
    }

    ul.list {
      padding-left: 8px;
      list-style: initial;
      list-style-position: inside;
    }

    .bullets {
      margin-top: 8px;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        &::before {
          content: "";
          width: 4px;
          height: 8px;
          display: inline-block;
          margin-right: 16px;
          background-color: ${colors.indiblue};
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    b {
      font-weight: 500;
    }
  }
`

export const CaseStudyTopic = styled.section`
  ${StyledSection} {
    &:first-child {
      padding-top: 120px;
    }

    &:last-child {
      padding-bottom: 56px;

      ${breakpoint.medium`
        padding-bottom: 120px;
      `}
    }
  }
`

export default StyledSection
